import React, {useState, useEffect} from 'react';
import {CircleMarker, Marker} from "react-leaflet";
import L from "leaflet";
import {getBusMarker} from "./helpers";
import ReactDOMServer from "react-dom/server";
import Bus from "./Bus";

const {useRef} = React;

export default function BusCoordinates({ coordinates, routeOptions, isActive, color, secondsRemaining }) {
    const [markerIcon, setState] = useState(getBusMarker(routeOptions, isActive, color));
    const [timeRemaining, setTimeRemaining] = useState(secondsRemaining);
    const markerRef = useRef(null);
    const timerRef = useRef();

    // useEffect(() => {
    //     if (timeRemaining === 0) {
    //         clearInterval(timerRef.current);
    //     }
    // }, [timeRemaining]);
    //
    // useEffect(() => {
    //     timerRef.current = setInterval(() => {
    //         setTimeRemaining((timeRemaining) => {
    //             return timeRemaining - 1;
    //         });
    //     }, 1000)
    //     return () => {
    //         clearInterval(timerRef.current);
    //     };
    // }, []);

    function generateTimeIcon() {
        return L.divIcon({
            className: "busTimerContainer",
            html:ReactDOMServer.renderToString(<span className="busTimer" style={{ backgroundColor: (routeOptions && routeOptions.color) || color }}>{`${timeRemaining}s`}</span>),
        });
    }

    return <>
            <Marker key={coordinates} icon={markerIcon} ref={markerRef} position={coordinates} />
            {(timeRemaining > 0 && isActive) && <Marker icon={generateTimeIcon()} ref={markerRef} position={coordinates} />}
        </>;
}
