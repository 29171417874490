import React from 'react';
import {Polyline, CircleMarker, Popup} from "react-leaflet";

function renderStop(stop, options) {
    const stopOptions = { color: (options && options.color) || '#268ec6', fill: true, fillOpacity: 1, weight: 5, fillColor: "white" }
    return (
        <CircleMarker key={stop.id} center={[stop.latitude, stop.longitude]} pathOptions={stopOptions} radius={5}>
            {/*<Popup>{stop.stopName}</Popup>*/}
        </CircleMarker>
    )
}

export default function MapRoutes({ polylineCoordinates, options, stops }) {
    return (
        <>
            <Polyline pathOptions={options} positions={polylineCoordinates} />
            {stops && stops.map(stop => renderStop(stop, options))}
        </>
    );
}
