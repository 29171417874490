import ReactDOMServer from 'react-dom/server';
import {isAndroid, isIOS} from 'react-device-detect';
import L from "leaflet";
import BusLogo from "./Bus.svg";
import Bus from "./Bus";

const INACTIVE_BUS_COLOR = "#A6A6A6";

export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function preProcessRoutes(routes) {
    const matches = routes.match(/\((.*?)\)/);
    if (matches) {
        const routesArray = matches[1].split(",");
        return routesArray.map(route => {
            const [lng, lat] = route.split(" ");
            return [lat, lng];
        });
    }
    return [];
}

export function preProcessStops(stops) {
    return stops;
}

export function preProcessRouteCenteredPoint(routeCenterPoint) {
    if (routeCenterPoint) {
        const latLng = routeCenterPoint.split(",");
        return latLng.length > 1 ? latLng : null;
    }
    return null;
}

export function sendMessage(data) {

    function sendAndroidMessage(data) {
        window.Android && window.Android.shareData(JSON.stringify(data));
    }

    function sendIosMessage(data) {
        window.webkit.messageHandlers.iosListener.postMessage(data);
    }

    try {
        if (isAndroid) {
            sendAndroidMessage(data);
        } else if (isIOS) {
            sendIosMessage(data);
        } else {
            const parentWindow = window.parent;
            parentWindow.postMessage(data, "*");
        }
    } catch (e) {
        console.log(e);
    }
}

export function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

export function getCustomMarker(customColor, showMarker) {
    const DEFAULT_COLOR = "#FFFF";
    const markerHtmlStyles = `border: .4rem solid ${customColor || DEFAULT_COLOR}`
    const showMarkerClass = showMarker ? "custom-pin" : "hide-pin";
    return L.divIcon({
        className: showMarkerClass,
        iconSize: [10, 10],
        iconAnchor: [10, 10],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span class="custom-marker" style="${markerHtmlStyles}" />`
    })
}

export function getBusMarker(routeOptions, isActive, color) {
    let busColor;
    if (routeOptions) {
        busColor = isActive ? routeOptions && routeOptions.color : INACTIVE_BUS_COLOR;
    } else {
        busColor = isActive ? (color || "#9ac559") : INACTIVE_BUS_COLOR ;
    }
    return L.divIcon({
        className: "custom",
        html:ReactDOMServer.renderToString(<Bus busColor={busColor} />),
        iconAnchor: null,
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null,
        iconSize: new L.Point(50, 50)
    });
}

export function calcDistance(lat1, lon1, lat2, lon2) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        const radlat1 = Math.PI * lat1/180;
        const radlat2 = Math.PI * lat2/180;
        const theta = lon1-lon2;
        const radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        return dist;
    }
}
