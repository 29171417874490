import React from 'react';
import {Marker, useMapEvents} from "react-leaflet";
import L from "leaflet";
import {calcDistance, getCustomMarker, sendMessage} from "./helpers";

const {useState, useEffect, useRef} = React;

export default function CustomCoordinates({ coordinates, liveUpdate = false, color = "#FFFF", showFixedMarker=false, flyTo= false, zoomLevel = 14 }) {
    const [position, setPosition] = useState(coordinates);
    const markerRef = useRef(null)
    const markerIcon = getCustomMarker(color, showFixedMarker);
    const [lastPosition, setLastPosition] = useState({lat: null, lng: null});
    const map = useMapEvents({
        moveend(e) {
            if (liveUpdate) {
                const marker = markerRef.current;
                const { lat, lng } = marker.getLatLng();
                const { lat: lastLat, lng: lastLng } = lastPosition;
                const distanceThreshold = calcDistance(lat, lng, lastLat, lastLng);
                if (distanceThreshold >= 0.075) {
                    sendMessage(marker.getLatLng());
                    setLastPosition(marker.getLatLng());
                }
            }
        },
        move(e) {
            if (liveUpdate) {
                const center = this.getCenter();
                setPosition(center);
            }
        }
    })

    useEffect(() => {
        if (map && flyTo) {
            map.flyTo(coordinates,zoomLevel, {duration: 1} );
        }
        setPosition(coordinates)
        }, [map, coordinates]);

    return <Marker icon={markerIcon} ref={markerRef} position={position} />;
}
