import React from 'react';

export default function Bus({busColor}) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
        <g id="bus" transform="translate(261 7929)">
            <g id="Group_3916" data-name="Group 3916" transform="translate(-261 -7929)">
                <circle id="Ellipse_20" data-name="Ellipse 20" cx="19" cy="19" r="19" fill="#fff"/>
            </g>
            <g id="Group_3915" data-name="Group 3915" transform="translate(-251 -7922)" style={{fill: busColor || "#000"}}>
                <path id="Subtraction_4" data-name="Subtraction 4"
                      d="M753.7,7681.447l-.358,0a1.433,1.433,0,0,1-1.386-1.473v-14.8c0-.764,0-1.05,1.386-1.41.169-.044.35-.093.543-.145l.021-.006a23.076,23.076,0,0,1,6.372-.954c3.817,0,5.878.6,6.934,1.1,1.388.663,1.388.663,1.388,1.473v14.734a1.434,1.434,0,0,1-1.388,1.473h-.357c.17-.092.43-.234.7-.4v-15.255H753v15.255c.264.159.528.3.7.4Zm3.052-17.51v.812h7.052v-.812Z"
                      transform="translate(-750.893 -7662.661)"/>
                <g id="Group_2266" data-name="Group 2266" transform="translate(0 8.111)">
                    <path id="Path_2226" data-name="Path 2226"
                          d="M941.334,230.156a.794.794,0,0,1,0-.792l.513-.792c.141-.218.257-.04.257.4v1.585c0,.436-.115.614-.257.4l-.513-.792"
                          transform="translate(-941.229 -228.479)"/>
                </g>
                <g id="Group_2267" data-name="Group 2267" transform="translate(17.936 8.111)">
                    <path id="Path_2227" data-name="Path 2227"
                          d="M1193.016,230.156a.8.8,0,0,0,0-.792l-.513-.792c-.141-.218-.256-.04-.256.4v1.585c0,.436.115.614.256.4l.513-.792"
                          transform="translate(-1192.247 -228.479)"/>
                </g>
                <g id="Group_2268" data-name="Group 2268" transform="translate(3.589 20.938)">
                    <rect id="Rectangle_306" data-name="Rectangle 306" width="2.12" height="3.106"/>
                </g>
                <g id="Group_2269" data-name="Group 2269" transform="translate(13.209 20.938)">
                    <rect id="Rectangle_307" data-name="Rectangle 307" width="2.12" height="3.106"/>
                </g>
                <g id="Group_2270" data-name="Group 2270" transform="translate(2.938 3.695)">
                    <path id="Path_2228" data-name="Path 2228" d="M995.284,175.738a16.8,16.8,0,0,1-12.934,0v-9.061h12.934Z"
                          transform="translate(-982.35 -166.677)"/>
                </g>
                <g id="Group_2271" data-name="Group 2271" transform="translate(11.246 16.124)">
                    <path id="Path_2229" data-name="Path 2229"
                          d="M1099.94,343.266a1.319,1.319,0,1,0-1.319-1.319,1.319,1.319,0,0,0,1.319,1.319"
                          transform="translate(-1098.621 -340.628)"/>
                </g>
                <g id="Group_2272" data-name="Group 2272" transform="translate(4.848 16.124)">
                    <path id="Path_2230" data-name="Path 2230"
                          d="M1010.4,343.266a1.319,1.319,0,1,0-1.319-1.319,1.319,1.319,0,0,0,1.319,1.319"
                          transform="translate(-1009.083 -340.628)"/>
                </g>
                <g id="Group_2273" data-name="Group 2273" transform="translate(1.078 13.934)">
                    <path id="Path_2231" data-name="Path 2231"
                          d="M956.322,310.071v3.719a4.578,4.578,0,0,0,.5,2.02,5.428,5.428,0,0,0,5.1,3.213h5.417a5.394,5.394,0,0,0,3.26-1.01l1.113-1.079.539-.8a5.313,5.313,0,0,0,.689-2.8v-3.259l-.092-.092h-.275l-.689.092a2.435,2.435,0,0,0-1.7,2.3v1.01a3.577,3.577,0,0,1-.138.964,2.931,2.931,0,0,1-2.984,1.836h-5a3.348,3.348,0,0,1-1.515-.321,2.694,2.694,0,0,1-1.469-2.2v-.78a4.11,4.11,0,0,0-.138-1.239,2.4,2.4,0,0,0-2.341-1.653h-.184Z"
                          transform="translate(-956.322 -309.979)"/>
                </g>
            </g>
        </g>
    </svg>;
}
